/* eslint-disable max-lines */
import { ZDTProduct, ZDTRatingsReviews } from '@zalora/doraemon-ts';
import { BreadcrumbItemType } from 'components/Breadcrumbs';
import { RecFeedType } from 'components/RecommendationFeed/RecommendationFeed';
import { ProductsPerRow } from 'constants/recommendations';
import { Routes } from 'constants/routes';
import { SHIPMENT_TYPES } from 'constants/shipment-types';
import { ONE_SIZE } from 'constants/sizes';
import { LotusProduct } from 'types/LDT';
import { ZDTCart } from 'types/ZDT';
import { ProductMediaType } from 'types/ZDTEnums';
import { getCategories as getCategoriesUtil } from 'utils/catalog-product';
import { getCategoryUrlBreadcrumbs } from './catalog/breadcrumbs';
import { Robots } from './catalog/types';
import { getEnvConfiguration } from './env-configuration';
import { captureError } from './raven';
import { getScreenSize, isMobileScreenSize } from './screen-size';

const ZALORA_SELLER_ID = '0';

export const isMarketplaceSimple = (simple: ZDTProduct.ProductSimple): boolean => {
  return simple.FulfillmentInformation?.SellerId !== ZALORA_SELLER_ID;
};

export const getSizeSystemBrand = (product: ZDTProduct.Product) => {
  const sizes = product.Sizes || {};
  const sizeSystemBrand = product.SizeSystemBrand || '__not_exists__'; // Set default value that never exists for edge-case below.

  const sizeSystemNames = Object.keys(sizes);

  if (sizeSystemNames.length === 0) {
    return sizeSystemBrand;
  }

  return sizes[sizeSystemBrand]
    ? sizeSystemBrand
    : // This is an edge-case where the API response
      // returns e.g. `product.SizeSystemBrand` = `EU` but doesn't have an
      // existing `product.Sizes.EU`. If happening, fallback to 1st size system.
      // https://zalora.slack.com/archives/C044AHR188G/p1669189270069299?thread_ts=1668392282.993039&cid=C044AHR188G
      sizeSystemNames[0];
};

export const isPreLovedProduct = (product: ZDTProduct.Product) => {
  const { Collections } = product;

  if (!Collections) {
    return false;
  }

  return Collections.some((collection) => collection.LabelName === 'Pre-Loved');
};

export const getSimpleBySize = (product?: ZDTProduct.Product, size?: ZDTProduct.Size) => {
  if (!product) {
    return undefined;
  }

  if (!size) {
    if (isOneSizeProduct(product)) {
      return product?.Simples?.[0];
    }

    return undefined;
  }

  // `product.Simples` only show a size from system brand, example: EU If a user
  // has selected e.g. "UK" size system with size "8" - Position 4 we need to
  // traverse the sizes of system brand EU to get the size with the same
  // position (4) Then we continue traverse product.Simple to get simple which
  // has size is equal to system brand label
  const sizeSystemBrand = getSizeSystemBrand(product);
  const productSizes = getSizesFromSimples(product);
  const brandSizeSystemSizes = productSizes[sizeSystemBrand];

  if (brandSizeSystemSizes) {
    const brandSize = brandSizeSystemSizes.find(
      (brandSize) => brandSize.Position === size.Position,
    );

    return product.Simples?.find((simple) => simple.Size === brandSize?.Label);
  }

  return undefined;
};

export const getSimpleBySimpleSku = (product?: ZDTProduct.Product, simpleSku?: string) => {
  if (!product || !simpleSku) {
    return undefined;
  }

  return product.Simples?.find((simple) => simple.SimpleSku === simpleSku);
};

export const isOneSizeProduct = (product: ZDTProduct.Product) => {
  if (
    product.Simples?.length === 1 &&
    (!product.Sizes || Object.keys(product.Sizes).length === 0)
  ) {
    return true;
  }

  return false;
};

export const isOfficialStoreProduct = (
  product: ZDTProduct.Product,
): product is ZDTProduct.Product & {
  OfficialstoreInformation: NonNullable<ZDTProduct.Product['OfficialstoreInformation']> & {
    ImageUrlPdv: NonNullable<
      NonNullable<ZDTProduct.Product['OfficialstoreInformation']>['ImageUrlPdv']
    >;
  };
} => {
  if (!product) {
    return false;
  }

  if ('OfficialstoreInformation' in product && product.OfficialstoreInformation) {
    return !!product.OfficialstoreInformation.ImageUrlPdv;
  }

  return false;
};

export const hasSizeInStock = (product?: ZDTProduct.Product, size?: ZDTProduct.Size) => {
  if (!product || !size) {
    return false;
  }

  const simple = isOneSizeProduct(product) ? product.Simples?.[0] : getSimpleBySize(product, size);

  if (!simple) {
    return false;
  }

  return simple.Quantity || 0 > 0;
};

export const isSimpleOutOfStock = (simple?: ZDTProduct.ProductSimple, cart?: ZDTCart.Cart) => {
  if (!simple || simple.Quantity === 0) {
    return true;
  }

  if (!cart || !cart.GroupedCartItems?.length) {
    return false;
  }

  return cart.GroupedCartItems.some((groupedCartItem) => {
    if (!groupedCartItem.CartItems) {
      return false;
    }

    return groupedCartItem.CartItems.some((cartItem) => {
      if (cartItem.SimpleSku === simple.SimpleSku) {
        return cartItem.Quantity >= (simple.Quantity || 0);
      }

      return false;
    });
  });
};

export const getSizesFromSimples = (
  product?: ZDTProduct.Product,
): NonNullable<ZDTProduct.Product['Sizes']> => {
  if (!product) {
    return {};
  }

  const { Simples } = product;
  const sizes = product.Sizes || {};
  const sizeSystemNames = Object.keys(sizes);
  const sizeSystemBrand = getSizeSystemBrand(product);
  const isEmptySizeSystem = sizeSystemNames.length === 0;

  // Case 1: product sizes is empty & simple is has 1 element
  // -> one size product
  if (isEmptySizeSystem && Simples?.length === 1) {
    return { [ONE_SIZE]: [{ Label: ONE_SIZE, Position: '0' }] };
  }

  // Case 2: Size system is empty or size of simple does not match with any size in product sizes
  // -> generate sizes from simple with SizeSystemBrand (don't use Sizes data)
  const isMissMatchedBetweenSimpleAndSizeSystem =
    isEmptySizeSystem ||
    Simples?.some((simple) => sizes[sizeSystemBrand].every((size) => size.Label !== simple.Size));

  if (isMissMatchedBetweenSimpleAndSizeSystem) {
    const sizes: ZDTProduct.Size[] = [];

    Simples?.forEach((simple, idx) => {
      if (simple.Size) {
        sizes.push({
          Label: simple.Size,
          Position: idx.toString(),
        });
      }
    });

    return {
      [sizeSystemBrand]: sizes,
    };
  }

  const ignoredSizePositions: string[] = sizes[sizeSystemBrand].reduce((acc, size) => {
    const isSimpleNotExists = Simples?.find((simple) => simple.Size === size.Label) === undefined;

    if (isSimpleNotExists) {
      acc.push(size.Position);
    }

    return acc;
  }, [] as string[]);

  const simpleSizes: ZDTProduct.Product['Sizes'] = sizeSystemNames.reduce(
    (simpleSizesAcc, sizeSystemName) => {
      simpleSizesAcc[sizeSystemName] = sizes[sizeSystemName].reduce((sizesAcc, size) => {
        const isValidSize = ignoredSizePositions.indexOf(size.Position) === -1;

        if (isValidSize) {
          sizesAcc.push(size);
        }

        return sizesAcc;
      }, [] as ZDTProduct.Size[]);

      return simpleSizesAcc;
    },
    {} as NonNullable<ZDTProduct.Product['Sizes']>,
  );

  return simpleSizes;
};

/**
 * Product can be out of date because of cached
 * PriceAndStockAvailability can not be cached, it always latest data
 *
 * -> merge some information from PriceAndStockAvailability to Product to get latest Product information
 */
export const getLatestProductInformation = (
  product: ZDTProduct.Product,
  priceAndStock?: ZDTProduct.ProductAvailability,
): ZDTProduct.Product => {
  if (!priceAndStock || product.ConfigSku !== priceAndStock.ConfigSku) {
    return product;
  }

  const Simples: ZDTProduct.ProductSimple[] = [];

  if (product.Simples) {
    product.Simples.forEach((simple) => {
      if (!simple.SimpleSku || !priceAndStock.Simples) {
        return;
      }

      const latestSimple = priceAndStock.Simples[simple.SimpleSku];

      if (latestSimple) {
        Simples.push({ ...simple, ...latestSimple });
      }
    });
  }

  return {
    ...product,
    Simples,
    // manual override fields here, avoid using `...`, it could make bug for object or array.
    HasDifferentSimplePrices: priceAndStock.HasDifferentSimplePrices,
    Price: priceAndStock.Price,
    SpecialPrice: priceAndStock.SpecialPrice,
    MarkdownLabel: priceAndStock.MarkdownLabel,
    SavingAmount: priceAndStock.SavingAmount || '',
  };
};

export const getBreadcrumbs = (product: LotusProduct) => {
  // there some case that product don't have segment info,
  // in this case, we should not show the breadcrumb
  if (!product || !('BreadcrumbsDetail' in product) || !product.BreadcrumbsDetail?.Segment?.Label) {
    return [];
  }

  const { Segment } = product.BreadcrumbsDetail;
  const breadcrumbs: BreadcrumbItemType[] = [];
  const categories = getBreadcrumbsCategories(product);

  // Hard code home
  breadcrumbs.push({
    label: 'Home',
    url: Routes.HOMEPAGE,
  });

  breadcrumbs.push({
    label: Segment.Label || '',
    url: `/s/${Segment.UrlKey}`,
  });

  for (let i = 0; i < categories.length; i++) {
    try {
      const cat = categories[i];

      if (!cat.Label) {
        continue;
      }

      const prevCategory = categories[i - 1];
      const categoryUrl = getCategoryUrlBreadcrumbs(cat, Segment, prevCategory);

      breadcrumbs.push({
        label: cat.Label,
        url: categoryUrl,
      });
    } catch (error) {
      captureError('Error when generate category URL', {
        error,
        tag: 'get-request',
      });
    }
  }

  return breadcrumbs;
};

export const getSourceSegment = (product: LotusProduct) => {
  if ('BreadcrumbsDetail' in product && product.BreadcrumbsDetail?.Segment?.UrlKey) {
    return product.BreadcrumbsDetail.Segment.UrlKey;
  }

  if ('Breadcrumbs' in product && product.Breadcrumbs?.length) {
    return product.Breadcrumbs[0];
  }

  return '';
};

export const getTotalReviews = (reviewStatistics: ZDTRatingsReviews.ProductReviewStatistics) => {
  const { ReviewCount } = reviewStatistics;

  return ReviewCount || 0;
};

export const getAverageReviewRating = (
  reviewStatistics: ZDTRatingsReviews.ProductReviewStatistics,
) => {
  const { AvgRating } = reviewStatistics;

  return AvgRating;
};

export const getTotalStockCount = (product: ZDTProduct.Product) => {
  const { Simples } = product;

  if (!Simples) {
    return 0;
  }

  return Simples.reduce((accumulator, simple) => accumulator + (simple.Quantity || 0), 0);
};

export const getSizeAvailability = (product: ZDTProduct.Product) => {
  const simples = product.Simples || [];

  return simples.filter((simple) => {
    return (simple.Quantity || 0) > 0;
  }).length;
};

export const getBreadcrumbsCategories = (product: LotusProduct) => {
  if (!('BreadcrumbsDetail' in product)) {
    return [];
  }

  const categories = product.BreadcrumbsDetail?.Categories || [];

  return categories.slice().sort((c1, c2) => Number(c1.Depth) - Number(c2.Depth));
};

export const getFirstBreadcrumbsCategoryLabel = (product: LotusProduct) => {
  const breadcrumbsCategories = getBreadcrumbsCategories(product);

  if (!breadcrumbsCategories.length) {
    return '';
  }

  return breadcrumbsCategories[0].Label || '';
};

export const getSubcategory = (product: LotusProduct) => {
  const categories = getBreadcrumbsCategories(product);

  if (!categories.length) {
    return '';
  }

  return categories[categories.length - 1].Label ?? '';
};

export const getSubcategoryBreadcrumbs = (product: LotusProduct) => {
  const breadcrumbs = getBreadcrumbs(product);

  return breadcrumbs.map((item) => item.label).join(' > ');
};

export const getConfigSkuBySimpleSku = (simpleSku: string) => {
  return simpleSku.split('-')[0] ?? simpleSku;
};

export const getCountrySkuByConfigSku = (configSku: string) => {
  return `${getEnvConfiguration('CC')?.toUpperCase()}${configSku}`;
};

export const getXPositionRecFeed = (
  index: number,
  layoutType: RecFeedType = 'grid',
  productsPerRow = {
    MOBILE: ProductsPerRow.MOBILE,
    TABLET: ProductsPerRow.TABLET,
    DESKTOP: ProductsPerRow.DESKTOP,
  },
) => {
  if (layoutType === 'horizontal') {
    return (index + 1).toString();
  }

  const screenSize = getScreenSize();
  const productsPerRowKey = isMobileScreenSize(screenSize) ? 'MOBILE' : screenSize.name;

  return ((index % productsPerRow[productsPerRowKey]) + 1).toString();
};

export const getYPositionRecFeed = (
  index: number,
  layoutType: RecFeedType = 'grid',
  productsPerRow = {
    MOBILE: ProductsPerRow.MOBILE,
    TABLET: ProductsPerRow.TABLET,
    DESKTOP: ProductsPerRow.DESKTOP,
  },
) => {
  if (layoutType === 'horizontal') {
    // In horizontal type all products are in the same row
    return '1';
  }

  const screenSize = getScreenSize();
  const productsPerRowKey = isMobileScreenSize(screenSize) ? 'MOBILE' : screenSize.name;

  return Math.ceil((index + 1) / productsPerRow[productsPerRowKey]).toString();
};

export const getProductPricing = (
  product: ZDTProduct.Product,
  selectedSimple?: ZDTProduct.ProductSimple,
) => {
  return {
    Price: selectedSimple ? selectedSimple.Price : product.Price,
    SpecialPrice: selectedSimple ? selectedSimple.SpecialPrice : product.SpecialPrice,
    MarkdownLabel: selectedSimple ? selectedSimple.MarkdownLabel : product.MarkdownLabel,
  };
};

export const getPrice = (product: LotusProduct) => {
  if ('PriceInDecimal' in product && product.PriceInDecimal !== null) {
    return product.PriceInDecimal;
  }

  if ('Price' in product && product.Price !== null) {
    return Number(product.Price);
  }

  return NaN;
};

export const getSpecialPrice = (product: LotusProduct) => {
  if (!product.SpecialPrice) {
    return NaN;
  }

  if (!isNaN(+product.SpecialPrice)) {
    return +product.SpecialPrice;
  }

  if ('SpecialPriceInDecimal' in product && product.SpecialPriceInDecimal !== null) {
    return product.SpecialPriceInDecimal;
  }

  return NaN;
};

export const getFinalPrice = (product: LotusProduct) => {
  const productPrice = getPrice(product);
  const productSpecialPrice = getSpecialPrice(product);

  if (productSpecialPrice) {
    return productSpecialPrice;
  }

  return productPrice;
};

export const getSkuList = (products: LotusProduct[]) => {
  const skus: string[] = [];

  products.forEach((product) => {
    if ('ConfigSku' in product && product.ConfigSku) {
      skus.push(product.ConfigSku);
    }
  });

  return skus;
};

export const getName = (product: LotusProduct) => {
  if ('Name' in product && product.Name) {
    return product.Name;
  }

  return '';
};

export const getConfigSku = (product: LotusProduct) => {
  if ('ConfigSku' in product && product.ConfigSku) {
    return product.ConfigSku;
  }

  return '';
};

export const getBrandName = (product: LotusProduct) => {
  if ('Brand' in product && product.Brand) {
    return product.Brand;
  }

  return '';
};

export const getMainImageURL = (product: LotusProduct) => {
  if ('MainImageUrl' in product && product.MainImageUrl) {
    return product.MainImageUrl;
  }

  return '';
};

export const getURL = (product: LotusProduct) => {
  if ('ProductUrl' in product && product.ProductUrl) {
    return product.ProductUrl;
  }

  if ('Url' in product && product.Url) {
    return product.Url;
  }

  return '';
};

export const getCategories = (product: LotusProduct) => {
  if ('Breadcrumbs' in product && product.Breadcrumbs) {
    return getCategoriesUtil(product);
  }

  if ('CategoryIds' in product && product.CategoryIds) {
    return product.CategoryIds;
  }

  return [];
};

export const getColour = (product: LotusProduct) => {
  if ('Color' in product && product.Color) {
    return product.Color;
  }

  return '';
};

export const isMarketplaceProduct = (
  product: LotusProduct,
  selectedSimple?: ZDTProduct.ProductSimple,
): boolean => {
  if (
    selectedSimple &&
    'FulfillmentInformation' in selectedSimple &&
    selectedSimple.FulfillmentInformation
  ) {
    return selectedSimple.FulfillmentInformation.SellerId !== ZALORA_SELLER_ID;
  }

  if ('FulfillmentInformation' in product && product.FulfillmentInformation) {
    return product.FulfillmentInformation.SellerId !== ZALORA_SELLER_ID;
  }

  return false;
};

export const getSellerName = (product: LotusProduct) => {
  if ('SellerName' in product && product.SellerName) {
    return product.SellerName;
  }

  if ('SupplierName' in product && product.SupplierName) {
    return product.SupplierName;
  }

  return '';
};

export const getReviewStatistics = (product: LotusProduct | ZDTProduct.ProductSearch) => {
  if ('ReviewStatistics' in product && product.ReviewStatistics) {
    return product.ReviewStatistics;
  }

  return null;
};

export const getRobots = (asPath: string) => {
  const [, query] = asPath.split('?');

  if (!query) {
    return Robots.INDEX_FOLLOW;
  }

  return Robots.NOINDEX_FOLLOW;
};

export const isVideoContentAvailable = (product: LotusProduct): boolean => {
  if ('MediaCollection' in product && product.MediaCollection) {
    return product.MediaCollection.some(
      (media) => media.ContentType === ProductMediaType.PRODUCT_MEDIA_TYPE_VIDEO,
    );
  }

  return false;
};

export const getImageCount = (product: LotusProduct): number => {
  let imageCount = 0;

  if ('MediaCollection' in product && product.MediaCollection) {
    product.MediaCollection.forEach((media) => {
      if (media.ContentType === ProductMediaType.PRODUCT_MEDIA_TYPE_IMAGE) {
        imageCount++;
      }
    });
  }

  return imageCount;
};

export const getProductDescriptionCount = (product: LotusProduct): number => {
  let descriptionLength = 0;

  if ('ShortDescription' in product && product.ShortDescription) {
    descriptionLength = product.ShortDescription.split(' ').filter((num) => {
      return num !== '';
    }).length;
  }

  return descriptionLength;
};

export const getProductAge = (product: LotusProduct) => {
  if ('Age' in product && product.Age) {
    return product.Age.toString();
  }

  return undefined;
};

export const getReplenishedAt = (product: LotusProduct) => {
  if ('ReplenishedAt' in product && product.ReplenishedAt) {
    return product.ReplenishedAt;
  }

  return undefined;
};

export const getDepartmentCode = (product: LotusProduct) => {
  if ('DepartmentCode' in product && product.DepartmentCode) {
    return product.DepartmentCode;
  }

  return undefined;
};

/**
 * return the label of sizes for the selected system size
 * @param product - product data
 * @param sizeSystem - optional, if not provided, the first system size will be used
 * @returns return the label of sizes for the selected system size
 */
export const getSizeLabelsForSizeSystem = (product: ZDTProduct.Product, sizeSystem?: string) => {
  const sizes = getSizesFromSimples(product);
  const selectedSizeSystem = sizeSystem || Object.keys(sizes)[0];

  return sizes[selectedSizeSystem]?.map((size) => size.Label) || [];
};

/**
 * return the label of sizes for the selected system size and in stock
 * @param product
 * @param sizeSystem
 * @returns
 */
export const getSizeLabelsInStock = (product: ZDTProduct.Product, sizeSystem?: string) => {
  const sizes = getSizesFromSimples(product);
  const selectedSizeSystem = sizeSystem || Object.keys(sizes)[0];

  const sizeLables = sizes?.[selectedSizeSystem] || [];

  return sizeLables
    .filter((size) => {
      return hasSizeInStock(product, size);
    })
    .map((size) => size.Label);
};

/**
 * Product Details endpoint new payload : Luxury
 * - If value is empty : non-luxury
 * - If value is not empty : luxury
 * @param product
 * @returns
 */
export const isLuxuryProduct = (product: ZDTProduct.Product): boolean => {
  if ('Luxury' in product && product.Luxury) {
    return !!product.Luxury;
  }

  return false;
};

/**
 * Condition of FBZ item:
 * - selectedSimple is valid
 * - ShipmentTypeNameEn === Cross Listing
 * - SellerId !== 0
 *
 * @param selectedSimple
 * @returns
 */
export const isFBZProduct = (selectedSimple?: ZDTProduct.ProductSimple): boolean => {
  if (!selectedSimple) {
    return false;
  }

  return (
    selectedSimple.ShipmentTypeNameEn === SHIPMENT_TYPES.CrossListing &&
    selectedSimple?.FulfillmentInformation?.SellerId !== '0'
  );
};
